<template>
  <div class="card mb-3">
    <div class="card-body d-flex flex-column h-100">
      <div class="row h-100">
        <div class="col-lg-3 p-0 d-flex flex-column">
          <img v-if="card.brand === 'Visa'" class="img-fluid my-auto" src="../../../../public/static/images/App/visa.png"/>
          <img v-if="card.brand === 'MasterCard'" class="img-fluid my-auto" src="../../../../public/static/images/App/master_card.png"/>
        </div>
        <div class="col-lg-9 pl-4 pr-4">
          <div class="row mb-2">
            <div class="col-lg-12">
              <span class="font-weight-bold">{{ $t("Credit card") }} **** {{ card.lastNumbers }}</span>
            </div>
          </div>
          <div v-show="!edit" class="row">
            <div class="col-lg-12">{{ $t("Card expire date") }} {{ card.expired.month }}/{{ card.expired.year }}</div>
          </div>
          <div v-show="edit" class="form-row">
            <div class="col">
              <ui-input-label :label="$t('Card expire date')" />
            </div>
          </div>
          <div v-show="edit" class="row">
            <div class="col">
              <ui-select
                v-model="card.expired.month"
                :validation="{required: true}"
                name="expired"
                :clearable="false"
                :filterable="false"
                :placeholder="$t('MM')"
                :options="month"
                :key-name="'choice'"
                :label-name="'choice'"
                :value-name="'choice'"
              />
            </div>
            <div class="col">
              <ui-select
                v-model="card.expired.year"
                :validation="{required: true}"
                name="expired"
                :clearable="false"
                :filterable="false"
                :placeholder="$t('YY')"
                :options="years"
                :key-name="'year'"
                :label-name="'year'"
                :value-name="'year'"
              />
            </div>
            <div class="col-lg-12">
              <button type="button" class="btn btn-sm btn-light btn-circle float-right shadow-sm" @click="save">{{ $t('SAVE_WEB') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer bg-transparent text-right">
      <button type="button" class="btn btn-sm btn-light btn-circle shadow-sm mx-1" @click="edit = !edit">{{ edit ? $t("Cancel") : $t("Edit") }}</button>
      <button type="button" class="btn btn-sm btn-light btn-circle shadow-sm" @click="remove">{{ $t("Remove") }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardTemplate',
  data () {
    return {
      card: {
        cardId: '',
        brand: '',
        lastNumbers: '',
        expired: {
          month: '',
          year: ''
        },
        subscriptionID: ''
      },
      edit: false
    }
  },
  created () {
    this.card.cardId = this.cardData.id
    this.card.expired.month = this.cardData.exp_month
    this.card.expired.year = this.cardData.exp_year
    this.card.lastNumbers = this.cardData.last4
    this.card.brand = this.cardData.brand
    this.card.subscriptionID = this.cardData.subscription_id
  },
  methods: {
    save () {
      const data = {
        exp_month: parseInt(this.card.expired.month),
        exp_year: parseInt(this.card.expired.year)
      }
      this.$store.dispatch('patchCard', [this.card.cardId, data]).then(response => {
        this.toastSuccess(this.$t('Card successfully edited'))
        this.edit = !this.edit
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    },
    remove () {
      if (this.card.subscriptionID) {
        this.$confirm(this.$i18n?.t('This card is used for autopayments. Deleting this card will deactivate autopayments'), this.$i18n?.t('Are you sure?'), {
          confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
          cancelButtonText: this.$i18n?.t('No, cancel it!!'),
          type: 'warning',
          center: true
        }).then(() => {
          this.disableSubscription()
        }).catch(() => {})
      } else {
        this.deleteCard()
      }
    },
    disableSubscription () {
      this.$store.dispatch('deleteSubscription', this.card.subscriptionID).then(response => {
        this.$store.dispatch('clearCompanyStore')
        this.$store.dispatch('getCompany', this.companyId)
        this.subscription = null
        this.toastSuccess(this.$t('Subscription is disable'))
        this.deleteCard()
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    },
    deleteCard () {
      this.$store.dispatch('deleteCard', this.card.cardId).then(response => {
        this.$store.dispatch('clearCompanyStore')
        this.$store.dispatch('getCompany', this.companyId)
        this.toastSuccess(this.$t('Card successfully deleted'))
        this.$eventBus.emit('removeCard')
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    }
  },
  props: ['month', 'years', 'cardData']
}
</script>

<style scoped>
  .el-select {
    width: 48%;
  }
</style>
