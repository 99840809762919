<template>
  <li class="nav-item dropdown border-top no-md-border my-auto">
    <div class="head-link nav-link pointer-cursor d-flex align-items-center pr-1 pl-0" id="mainMenuDropdown"
         data-toggle="dropdown"
         aria-haspopup="true" aria-expanded="false">
                  <span class="p-0 d-flex align-items-center">
                    <Avatar
                      ref="avatar"
                      data-cy="profile-menu"
                      class="rounded-circle d-inline-block m-0 m-auto pl-1"
                      :width="30"
                      :borderRadius="50"
                      :placeholder="false"
                      :userId="userId"
                      :mini="true"
                      :cache="headerCache">
                    </Avatar>
                  </span>
      <span class="menu-color d-md-none" :class="{ mobileItemStyle: isMobile }">{{ fullname }}</span>
    </div>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="mainMenuDropdown">
      <router-link v-if="workMode === 'work'" id="demo_dashboard" class="dropdown-item" :to="`/dashboard`">
        <i class="icon-grid"></i>
        <span class="menu-color ml-2">{{ $t('Calendar') }}</span>
      </router-link>

      <router-link id="demo_profile" class="dropdown-item" to="/profile#general">
        <i class="icon-user"></i>
        <span class="menu-color ml-2">{{ $t('My profile') }}</span>
      </router-link>

      <a v-if="Profile.register_provider === 'form'" id="demo_change_password" class="dropdown-item" @click="openChangePasswordModal" data-toggle="modal"
         style="cursor: pointer"
         data-target="#ChangePassword">
        <i class="icon-lock"></i>
        <span class="menu-color ml-2">{{ $t('Change password') }}</span>
      </a>

      <div class="dropdown-divider"></div>

      <a id="demo_logout" data-cy="logout" href="/logout" class="dropdown-item" @click.prevent="logout">
        <i class="icon-log-out"></i>
        <span class="menu-color ml-2">{{ $t('Log Out') }}</span>
      </a>
    </div>
  </li>
</template>
<script>
import Avatar from '@/components/CommonComponents/Avatar'

export default {
  name: 'HeaderUserComponent',
  components: { Avatar },
  data () {
    return {
      headerCache: true
    }
  },
  props: {
    isMobile: Boolean
  },
  created () {
    this.$eventBus.on('updateAvatar', () => {
      this.headerCache = false
      this.$refs.avatar.getAvatar()
    })
  },
  computed: {
    userId () {
      return this.$store.getters.profileId
    },
    fullname () {
      return this.$store.getters.userName
    },
    Profile () {
      return this.$store.getters.Profile
    },
    avatarHash () {
      return this.$store.getters.avatarHashId
    }
  },
  watch: {
    avatarHash: function () {
      // отслеживаем загрузку нового аватара пользователем и инициализируем обновление аватарки в хедере
      this.headerCache = false
      setTimeout(() => {
        this.headerCache = true
      }, 2000)
    }
  },
  methods: {
    openChangePasswordModal () {
      this.$emit('openChangePasswordModal')
    },
    logout () {
      this.$store.dispatch('logout', this.$store.getters.locale).then(() => {
      }).catch(() => {
      })
    }
  }
}
</script>
<style scoped>

.menu-color {
  color: #4a4a4a !important;
}

.mobileItemStyle {
  padding-left: 1.25rem;
}
</style>
