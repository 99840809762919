<template>
  <div class="row mt-2">
    <div class="col-md-3">
      <avatar
        v-if="userInfoState.id"
        :width="180"
        :borderRadius="50"
        :userId="userInfoState.user_id"
        :placeholder="true"
        :placeholderUrl="'../../../static/images/App/placeholder.png'"
        :cache="true">
      </avatar>
      <div align="center" class="font-weight-bold">
        {{firstName}} {{lastName}}
      </div>
    </div>
    <Form ref="observer" as="div" class="col-md-9">
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label font-weight-light">{{ $t('User ID:') }} {{ userInfoState.user_id }}</span>
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <label class="control-label font-weight-light">{{ $t('Time zone') }}:
            {{userInfoState.time_zone}}</label>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <ui-text-input
            name="firstName"
            v-model="firstName"
            :validation="{required: true, max: 128}"
            :label="$t('First name')"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <ui-text-input
            name="lastName"
            v-model="lastName"
            :validation="{required: true, max: 128}"
            :label="$t('Last name')"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <div class="control-label font-weight-bold mb-2">{{ $t('Phone number') }}</div>
          <ui-tel-input
            v-model="phone"
            name="phone"
            id="phone"
            :validation="{required: false, max: 20}"
            @isPhoneValid="(val) => this.isPhoneValid = val"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <ui-text-input
            name="email"
            v-model="email"
            :validation="{required: !!email, email: !!email}"
            :label="$t('E-mail')"
            :placeholder="$t('Enter valid email')"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <ui-select
            name="positions"
            v-model="positions"
            :clearable="true"
            :placeholder="$t('Select')"
            :label="$t('POSITIONS_SELECT')"
            :hint="$t('POSITIONS_SELECT_HINT')"
            :options="options"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end" v-if="positionChanged">
          <ui-date-picker
            name="replace_date"
            :clearable="false"
            :label="$t('POSITION_REPLACE_DATE')"
            :hint="$t('POSITION_REPLACE_DATE_HINT')"
            :validation="{required: true}"
            v-model="dateFrom"
          />
        </div>
        <div v-if="workMode === 'work'" class="col-12 col-lg-6 align-self-end">
          <ui-number-input
            name="required_working_time"
            v-model="required_working_time"
            :validation="{required: false, decimal: 2}"
            :label="$t('EMPLOYEE_REQUIRED_WORKING_TIME')"
            :hint="$t('EMPLOYEE_REQUIRED_WORKING_TIME_TOOLTIP')"
            :precision="2"
            :min="0"
            :step="0.1"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end mt-2">
          <ui-text-input
            name="identifier"
            v-model="userInfoState.identifier"
            :validation="{required: false, max: 60}"
            :label="$t('EMPLOYEE_IDENTIFIER')"
            :hint="$t('EMPLOYEE_IDENTIFIER_TOOLTIP')"
          />
        </div>
        <div v-if="workMode === 'work'" class="col-12 col-lg-6 align-self-end mt-2">
          <ui-number-input
            name="job_part"
            v-model="job_part"
            :validation="{required: true, integer: true, min_value: 0}"
            :label="$t('EMPLOYEE_JOB_PART')"
            :hint="$t('EMPLOYEE_JOB_PART_TOOLTIP')"
            :precision="0"
            :step="1"
            :min="0"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <ui-multi-select
            name="skills"
            v-model="skills"
            :clearable="true"
            :placeholder="$t('Select')"
            :label="$t('SKILLS_SELECT')"
            :hint="$t('SKILLS_SELECT_HINT')"
            :options="skillsOptions"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end mt-2">
          <ui-color-picker
            name="color"
            v-model="color"
            :colors="colorsChoices"
            :label="$t('EMPLOYEE_COLOR')"
            :hint="$t('EMPLOYEE_COLOR_TOOLTIP')"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <label class="font-weight-bold mb-2">
            {{ $t('WORK_DAYS_EMPLOYEE') }}
            <ui-hint :content="$t('WORK_DAYS_EMPLOYEE_HINT')" />
          </label>
          <ui-checkbox
            name="isPersonalWorkDays"
            v-model="isPersonalWorkDays"
            :label="$t('IS_PERSONAL_WORK_DAYS')"
            :hint="$t('IS_PERSONAL_WORK_DAYS_HINT')"
          />
          <div class="row mt-2">
            <div
              v-for="(item, index) in workDays"
              :key="index"
              class="col-auto"
            >
              <ui-checkbox
                :name="index + '__' + item.name"
                :disabled="!isPersonalWorkDays"
                v-model="item.is_work_day"
                :label="$t(item.name)"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isConfirmed" class="row mt-2">
        <div class="col">
          <el-alert
            show-icon
            center
            :title="$t('UNCONFIRMED_PHONE_WARNING')"
            type="warning">
          </el-alert>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col text-center">
          <button
            :disabled="!isConfirmed"
            class="btn btn-primary rounded-pill shadow m-2"
            @click="sendInvite">
            {{ $t('Invite') }}
          </button>
          <button class="btn btn-success rounded-pill shadow m-2" @click="save">
            {{ $t('SAVE_WEB') }}
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from '@/components/CommonComponents/Avatar'
import moment from 'moment-timezone'
import momentMixin from '@/mixins/mixinMoment'
import mixinColors from '@/mixins/mixinColors'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'CompanyUserInfo',
  mixins: [momentMixin, mixinColors, errorMixin],
  components: { Avatar },
  data () {
    return {
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      required_working_time: null,
      positions: null,
      initPosition: null,
      dateFrom: moment().startOf('day').format(this.backendDateTimeFormat),
      job_part: 100,
      skills: [],
      options: [],
      isPhoneValid: true,
      saved: false,
      color: '#961800',
      isPersonalWorkDays: false,
      workDays: null,
      userInfoState: {}
    }
  },
  props: { userInfo: Object },
  created () {
    this.userInfoState = this.userInfo
    if (this.userInfoState.work_days) {
      this.isPersonalWorkDays = true
      this.workDays = this.userInfoState.work_days
    } else {
      this.handleWorkDays()
    }
    this.$store.dispatch('getPositions', this.$route.params.id).then((response) => {
      this.options = response
    })
    this.$store.dispatch('getSkills', this.$route.params.id)
    this.firstName = this.userInfoState.first_name
    this.lastName = this.userInfoState.last_name
    this.email = this.userInfoState.email
    this.color = this.userInfoState.color
    this.required_working_time = this.userInfoState.required_working_time_minutes !== null
      ? Math.round((this.userInfoState.required_working_time_minutes / 60 + Number.EPSILON) * 100) / 100
      : null
    if (this.required_working_time === null) {
      this.required_working_time = this.baseWT ? this.baseWT : 0
    }
    if (this.userInfoState.job_part) {
      this.job_part = Math.round(this.userInfoState.job_part * 100) / 100
    }
    const positions = this.userInfoState.positions.length > 0 ? this.userInfoState.positions[0].id : null
    this.positions = positions
    this.initPosition = positions
    this.skills = this.userInfoState.skills?.length > 0 ? this.userInfoState.skills.map(item => item.id) : []
    if (this.userInfoState.phone) {
      this.phone = '+' + this.userInfoState.phone
    }
    // console.log(this.userInfo)
  },
  watch: {},
  computed: {
    ...mapGetters(['avatar']),
    isConfirmed () {
      return this.$store.getters.Profile.phone !== null
    },
    companyRequiredWorkingTime () {
      let companyWT = this.$store.getters.company.required_working_time_minutes
      if (companyWT === null) return this.$t('Not implemented')
      companyWT = Math.round((companyWT / 60 + Number.EPSILON) * 100) / 100
      return companyWT.toString()
    },
    baseWT () {
      const companyWT = this.$store.getters.company.required_working_time_minutes
      if (companyWT === null) return null
      return Math.round((companyWT / 60 + Number.EPSILON) * 100) / 100
    },
    positionChanged () {
      return (this.positions ? this.positions : null) !== this.initPosition
    },
    skillsOptions () {
      return this.$store.getters.skills
    }
  },
  methods: {
    handleDaysChange (val, index) {
      this.workDays[index].is_work_day = val
    },
    handleWorkDays () {
      this.workDays = Object.assign([], this.$store.getters.company.work_days)
      this.workDays.sort((a, b) => {
        if (a.index > b.index) {
          return 1
        }
        if (a.index < b.index) {
          return -1
        }
        return 0
      })
      let firstDay = +this.$store.getters.company.salary.week_start + 1
      if (firstDay === 7) {
        firstDay = 0
      }
      const startArray = this.workDays.splice(firstDay)
      this.workDays = startArray.concat(this.workDays)
    },
    parsePhoneNumber (number) {
      if (number) {
        number = number.replace('+', '')
        number = number.replace(/\s/g, '')
      }
      return number
    },
    sendInvite () {
      this.save().then(result => {
        // console.log(this.saved)
        setTimeout(() => {
          if (result && this.saved) {
            this.saved = false
            this.$store.dispatch('inviteCompanyUsers', [this.userInfoState.id]).then(() => {
              this.toastSuccess(this.$t('Success'))
            }).catch((error) => {
              this.toastError(this.$t(error.response.data.message))
            })
          }
        }, 500)
      })
    },
    save () {
      return new Promise((resolve, reject) => {
        if (this.phone && !this.isPhoneValid) {
          return false
        }
        this.$refs.observer.validate().then(value => {
          if (value.valid) {
            const data = {
              first_name: this.firstName,
              last_name: this.lastName,
              phone: this.isPhoneValid ? this.parsePhoneNumber(this.phone) : '',
              email: this.email ? this.email : '',
              required_working_time_minutes: this.required_working_time
                ? Math.round(this.required_working_time * 60 + Number.EPSILON)
                : this.required_working_time,
              identifier: this.userInfoState.identifier,
              job_part: this.job_part,
              skills: this.skills,
              color: this.color
            }
            if (!this.isPersonalWorkDays) {
              data.work_days = null
            } else {
              data.work_days = this.workDays
            }

            const positionPayload = {
              positions_id: this.positions ? this.positions : null
            }

            if (this.isEmployeeHasPermission('get-employee-admin')) {
              this.patchAsAdmin(data)
            } else if (this.isEmployeeHasPermission('get related projects full data')) {
              this.patchAsManager(data)
            }

            if (this.positionChanged) {
              positionPayload.date = this.fromZoneToZone(this.dateFrom, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
              this.$store.dispatch('attachPositions', [this.$route.params.id, this.userInfoState.id, positionPayload])
                .then((response) => {
                  this.initPosition = positionPayload.positions_id
                  resolve(true)
                }).catch(() => {
                  resolve(false)
                })
            }
          } else {
            resolve(false)
          }
        })
      })
    },
    patchAsManager (data) {
      this.projectId = this.$route.params.projectId
      if (!this.projectId) {
        this.projectId = localStorage.getItem('currentProject')
      }
      // console.log(this.projectId, this.project)
      this.$store.dispatch('patchProjectEmployee', [this.projectId, this.userInfoState.id, data]).then(response => {
        this.$eventBus.emit('refetchUser')
        if (response.required_working_time_minutes === null) {
          this.required_working_time = this.baseWT
        }
        this.saved = true
      }).catch((e) => {
        this.saved = false
        if (e.response) {
          Object.keys(e.response.data.errors).forEach(key => {
            this.errors.add({
              field: key,
              msg: e.response.data.errors[key][0]
            })
          })
        }
      })
    },
    patchAsAdmin (data) {
      this.$store.dispatch('patchEmployee', [this.$store.getters.companyId, this.userInfoState.id, data]).then(response => {
        this.$eventBus.emit('refetchUser')
        if (response.required_working_time_minutes === null) {
          this.required_working_time = this.baseWT
        }
        this.saved = true
      }).catch((e) => {
        // console.log(2, e)
        this.saved = false
        if (e.response) {
          Object.keys(e.response.data.errors).forEach(key => {
            this.errors.add({
              field: key,
              msg: e.response.data.errors[key][0]
            })
          })
        }
        // console.log(123, e.response.data)
      })
    }
  }
}
</script>

<style>
  #phone > div > ul {
    z-index: 5!important;
  }
</style>
